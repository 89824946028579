import React, { Component } from "react"

import Layout from "../../../components/layout"
import ProductPage from "../../../components/productpage"

import image_product from '../../../images/product/k201.jpg'
import image_product_mobile from '../../../images/product/k201_mobile.jpg'

import substratelabels from "../../../content/substratelabels.json"

class K201Page extends Component {

	render() {
		return (
		  <Layout mobileTitle={true} 
		  		customClass='product-title'
		  		activePath='product'
		  		pageTitle="Konstrukt&trade; Permaplast&trade; K-201 High-Performance Acrylic Skimcoat">
		  	<ProductPage 
		  		pageTitle="Konstrukt&trade; Permaplast&trade; K-201 High-Performance Acrylic Skimcoat"
		  		productID={"74"}
		  		image_product={image_product}
		  		image_product_mobile={image_product_mobile}
		  		surfacelist={
		  			[
		  				{
		  					"code":0,
		  					"key":"cepn",
		  					"name":substratelabels['cepn']
		  				}
		  			]
		  		}
		  		/>
		  </Layout>
		);
	}
}
export default K201Page
